.login-page {
  background: #284d75;
  min-height: 100vh;
  width: 100%;
}

.login-left {
  background: url(../../assets/icons/left_bg.svg) no-repeat;
  background-size: 120%;
  background-position: left 50%;
  padding: 0rem 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  display: flex;
}
.login-right-section {
  background-color: #ffffff;
  border-radius: 10px;
  min-width: 35rem;
  min-height: 60vh;
  padding: 3rem 4rem;
}
.right_content label {
  font-weight: 600;
  font-size: 2.5rem;
  letter-spacing: 0px;
  color: #fff !important;
}
.right_content span {
  font-weight: 600;
  font-size: 2.5rem;
  margin-top: 0.5rem;
  color: #fff !important;
  position: relative;
  z-index: 10;
}

.right_content {
  background: linear-gradient(to right, #ffffff6d, transparent);
  padding: 1rem 0;
}

/* .right_content span::before {
  left: -15px;
  top: 0;
  content: "";
  position: absolute;
  width: 110%;
  height: 100%;
  background: #fae38a;
  transform: skewX(-15deg);
  z-index: -1;
} */

.login-form .input-wrap label {
  color: #333 !important;
  font-weight: 500;
}

.footer-note {
  max-width: 75%;
  margin: auto;
  text-align: center;
}
.footer-note,
.or,
.login-form label span {
  color: #695a5a !important;
  font-weight: 500;
}

.login-form h3 {
  font-size: 2.5rem !important;
  margin-bottom: 5px !important;
}

.login-form input {
  height: 42px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
}

.login-form .ant-form-item-label {
  color: #212121;
  font-weight: 600;
  font-size: 1.1rem;
}

.login-form .ant-input-affix-wrapper {
  padding: 0 11px !important;
  border-radius: 6px;
}

.emp-text {
  color: #333;
  font-weight: 600;
}

.head-sec h3 {
  font-size: 2rem !important;
  color: #293241 !important;
}
.head-sec span.ant-typography {
  color: #7676769f !important;
  font-weight: 500 !important;
  font-size: 0.9rem;
}
.head-sec {
  margin-bottom: 3rem;
}
.login-form .link,
.link {
  color: #1b84ff !important;
  font-weight: 500;
}
.head-sec p {
  margin-top: 0.5rem;
}

.login-right {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.login-right .logo {
  width: 90px;
  position: relative;
  left: 10px;
  top: 20px;
}

.login-right h3 {
  color: var(--text-primary);
  font-size: 2.3rem;
}

.login-right p {
  color: var(--text-secondary);
  font-size: 1.2rem;
}

.loginButtonsDiv {
  margin-top: 2rem !important;
}

.ant-form-item-row {
  margin-bottom: 1rem;
}
.credit-modal .ant-form-item-row {
  margin-bottom: 0;
}
.mainLoginDiv {
  width: 400px;
}

.head-sec h3 {
  font-size: 2.5rem;
}

.head-sec h4 {
  font-size: 2rem;
}

.head-sec p {
  margin-top: 0.5rem;
}

.head-sec {
  margin: 1.8rem 0;
}

.emp-text {
  color: #f48c32;
}

.footer-note {
  font-size: 1rem;
}

.footer-note b {
  color: white;
}

.login-right {
  margin-left: 3rem;
}

.login-right img {
  width: 90%;
}

.login-right h3 {
  color: var(--text-primary);
  font-size: 2.3rem;
}

.login-right p {
  color: var(--text-secondary);
  font-size: 1.2rem;
}

.icon-background {
  background: #656565 0% 0% no-repeat padding-box;
  border-radius: 18px;
  width: 65px;
  height: 65px;
}

.icon-background img {
  margin: 16px;
  width: 35px;
}

button span {
  color: var(--text-white);
}

.head-sec.welcome-text {
  margin-top: 0rem !important;
}

.ant-form-item-label label {
  color: var(--text-white);
}

.loginButton {
  background: var(--primary-color) !important;
  border-radius: 0.3rem !important;
  border: var(--primary-color) !important;
  min-width: 7rem;
  height: 40px !important;
}

.loginSwitchSimulatorButton {
  border: 1px solid #ffffff !important;
  border-radius: 7px !important;
  background-color: transparent !important;
}

.loginFormDiv {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.loginButtonsDiv > div > div > div {
  display: flex !important;
  justify-content: space-between !important;
}
.ant-modal-body .ant-form-item-label > label {
  color: #333 !important;
}

.table.tabs {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.table.tab.active {
  border-bottom: 3px solid #ee6c4d;
  color: #ee6c4d;
}
.table.tab {
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  height: 3rem;
  margin-right: 5px;
  padding: 0 1rem;
  color: #212121;
}
