@import url(./components/Clients/loan.css);
@import url(./components/Common/styles/bill.css);
@import url(./components/Common/styles/new-report.css);
@import url(./components/Common/styles/profile.css);

#root {
  background: #f5f7fa;
  overflow: hidden;
  min-height: 100vh;
}

.flex-row {
  flex-direction: row !important;
}

.login-form .logo {
  width: 160px;
  margin-top: 2rem;
}
.ant-form-item {
  margin-bottom: 0 !important;
}
.ant-form-item-row {
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.login-form h2,
.logo h2 {
  font-size: 3em;
  font-weight: bold;
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  /* Orange gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Safari/Chrome */
  background-clip: text;
  color: transparent;
}

.card {
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.6);
}

.ant-col.card {
  padding: 0;
}

.gap-1 {
  gap: 1rem;
}

.pp-main-button {
  border-radius: 5px !important;
  background-color: #3f81f4 !important;
  padding: 10px 15px !important;
  align-items: center;
  display: flex !important;
  justify-content: center;
  height: 42px !important;
  min-width: 100px;
}

.ant-table-cell {
  color: #454545;
  font-weight: 600;
}

.text-dim {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-white {
  color: white !important;
}

.flex-100 {
  flex: 1;
}

.user-col {
  min-width: 150px;
}

.user-col-lg {
  min-width: 200px;
}

.ant-table-container {
  overflow: auto;
}

.credit-section span {
  margin-right: 8px;
}

.credit-section {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 16px;
}

.right-alinged-modal {
  float: right;
  top: 0px !important;
  width: 35rem !important;
  padding-bottom: 0;
}

.right-alinged-modal.credit-modal {
  width: 55rem !important;
}

.right-alinged-modal.plan-modal {
  width: 100% !important;
  max-width: 100% !important;
}

.right-alinged-modal .ant-modal-content {
  min-height: 100vh;
}
.right-alinged-modal {
  min-height: 100vh;
  padding-bottom: 0px !important;
}
.errorMsg {
  color: red;
}
.ant-checkbox-checked {
  height: 1rem;
}

.ant-checkbox-checked::after {
  display: none;
}

.ant-checkbox-checked::after {
  border: none;
}

.error {
  color: red;
  margin-top: 5px;
}

.star::before {
  content: "* ";
  color: red;
}

/* 
.grid {
  display: grid;
  max-width: 100%;
  grid-gap: 1.6rem;
  margin: 0 1rem;
}

.main-area {
  grid-template-areas:
    "header header header"
    "nav main_content main_content"
    "footer footer footer";
}

.g-header {
  grid-area: header;
  width: 97%;
  position: fixed;
  z-index: 1000;
  background: #f5f7fa;
}

.g-footer {
  grid-area: footer;
}

.g-nav {
  grid-area: nav;
  position: fixed;
  top: 9.4rem;
}

.g-content {
  grid-area: main_content;
  width: 85%;
  position: relative;
  left: 13%;
  top: 10%;
  margin-bottom: 6rem;
} */
.approve,
.reject,
.delete,
.cancel {
  min-width: 8rem;
  padding: 0 1rem;
  border-radius: 10px;
  font-weight: 500;
  height: 42px;
}

.approve {
  width: 100px;
  background: #48b553 0 0 no-repeat padding-box;
}

.reject {
  background: #efa42c 0 0 no-repeat padding-box;
  color: #fff;
}

.delete {
  background: #ff4d4f 0 0 no-repeat padding-box;
  color: #fff;
}

.cancel {
  background: #fff;
  color: #1890ff !important;
  border: 1px solid #1890ff;
}

.ant-input[disabled],
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.8) !important;
  background: #f5f5f5 !important;
  cursor: not-allowed !important;
}

.credit-modal .ant-modal-footer {
  display: block;
}

.credit-modal .head-details {
  display: flex;
  background: #f0f4f8;
  padding: 10px 5px;
}
.credit-modal .head-details h3 {
  font-size: 1.1rem;
  width: 12rem;
}

/* .credit-modal th.ant-table-cell {
  position: sticky;
  top: 0;
}

.credit-modal table thead {
  position: sticky;
  top: 0;
  z-index: 100;
} */
.credit-modal .ant-table-container {
  height: 46vh;
}
.credit-modal .permissions ul {
  display: flex;
}

.credit-modal .permissions ul li {
  font-size: 1rem;
  width: 12rem;
}

.view-plan .permissions ul li {
  font-size: 1rem;
  width: 15rem;
  padding-left: 5px;
}
.view-plan .permissions ul {
  margin-top: 12px;
}
.modal-title {
  display: flex;
  flex-direction: column;
}
.modal-head {
  color: #122549;
  font-size: 1.6rem !important;
  margin-bottom: 8px;
}
.modal-subtitle {
  font-size: 12px;
  color: #999;
}

.modal-content h2 {
  margin-top: 0;
  font-size: 24px;
}

.price {
  font-size: 24px;
  font-weight: bold;
  margin: 8px 0;
}

.price span {
  font-size: 16px;
  font-weight: normal;
}

.modal-content p {
  font-size: 14px;
  margin-bottom: 8px;
}

.modal-content a {
  color: #007bff;
  text-decoration: none;
}

.modal-content a:hover {
  text-decoration: underline;
}

.card-price {
  font-size: 2rem;
  font-weight: bold;
  margin: 8px 0;
}

.per-credit {
  font-size: 1rem;
  font-weight: normal;
  color: #293241;
}

.card-description {
  font-size: 14px;
  margin-bottom: 8px;
  color: #293241;
}

.card-details {
  margin-bottom: 16px;
}

.card-details a {
  color: var(--primary-color);
  text-decoration: none;
}

.card-details a:hover {
  text-decoration: underline;
}

.card-credits {
  margin-bottom: 16px;
}

.credits-available {
  color: #122549;
  font-size: 1.1rem;
  font-weight: 600;
  position: relative;
  left: 1.5rem;
}

.credits-available::before {
  background-color: #60ba99;
  position: absolute;
  content: "";
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  left: -1.2rem;
  top: 0.4rem;
}

.status {
  border-radius: 7px;
  padding: 0.3rem 1.5rem;
  display: inline-block;
  min-width: 90px;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
  font-size: 1rem;
}

.status::before {
  position: absolute;
  content: "";
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  left: 0.7rem;
  top: 46%;
}
.status_paid {
  color: #35aa09 !important;
  text-transform: capitalize;
}

.status_payment_pending {
  color: #f6a907 !important;
  text-transform: capitalize;
}
.status_pending_approval,
.status_payment_pending,
.status_partially_paid,
.status_in_progress,
.status_saved,
.status_open,
.status_pending,
.status_InProgress {
  border-color: #f6a907;
  color: #f6a907;
  /* background: #fef4de; */
}

.status_payment_pending::before,
.status_pending_approval::before,
.status_partially_paid::before,
.status_unsubmitted::before,
.status_open::before,
.status_pending::before,
.status_saved::before,
.status_in_progress::before,
.status_InProgress::before {
  background-color: #f6a907;
}

.status_pending_reimbursement,
.status_pending_recovery,
.status_link_created,
.status_recovered,
.status_inprogress,
.status_reimbursed {
  border-color: #45a0fe;
  color: #45a0fe;
  /* background: #e1f0ff; */
}

.status_pending_reimbursement::before,
.status_link_created::before,
.status_inprogress::before,
.status_pending_recovery::before,
.status_recovered::before,
.status_reimbursed::before {
  background-color: #45a0fe;
}

.status_approved,
.status_success,
.status_confirmed,
.pay-table .status_closed,
.status_accepted,
.status_paid,
.status_payment_success,
.status_active,
.status_completed,
.status_reported {
  border-color: #35aa09;
  color: #35aa09;
}

.status_approved::before,
.status_closed::before,
.status_confirmed::before,
.status_success::before,
.pay-table .status_closed::before,
.status_accepted::before,
.status_completed::before,
.status_payment_success::before,
.status_paid::before,
.status_active::before,
.status_reported::before {
  background-color: #35aa09;
}

.status_rejected,
.status_declined,
/* .status_closed, */
.status_failed,
.status_FAILURE,
.status_cancelled {
  border-color: #f15363;
  color: #f15363;
}

.status_rejected::before,
.status_failed::before,
.status_declined::before,
/* .status_closed::before, */
.status_FAILURE::before ,
.status_cancelled::before {
  background-color: #f15363;
}

.status_text_pending_approval {
  color: #f6a907;
}

.status_text_pending_reimbursement {
  color: #45a0fe;
}

.status_text_approved {
  color: #35aa09;
}

.status_text_rejected {
  color: #f15363;
}

.back-card {
  background: #f5f7fa;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.totals {
  display: flex;
  justify-content: space-between;
}
.totals p {
  display: block;
  margin-bottom: 0.5rem !important;
}
.totals p span {
  display: block;
  min-width: 15rem;
}

.totals p.values span {
  color: #3c4853;
  font-weight: 600;
  min-width: 10rem;
  text-align: right;
}

.ant-table-cell-row-hover {
  cursor: pointer;
}

.pp-dropdownbutton button,
.pp-dropdownbutton .ant-btn {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
  color: #fff !important;
  font-weight: 600;
  height: 40px;
  background-color: var(--primary-color) !important;
}
.pp-button {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
  color: #fff !important;
  font-weight: 600;
  height: 40px;
  background-color: var(--primary-color) !important;
  padding: 0 2rem;
  border-radius: 8px;
  min-width: fit-content;
}

.c-more .ant-btn {
  height: 40px;
  margin-left: 0.5rem;
  padding: 0;
  border-radius: 8px !important;
}
.pp-dropdownbutton
  .ant-btn-compact-item.ant-btn-primary:not([disabled])
  + .ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
  background-color: var(--primary-color);
  height: 0;
}

.comp-sec h3 {
  font-size: 1.1rem;
}
.comp-sec h3 label {
  margin-right: 8px;
}

.header h3 {
  margin-bottom: 0px !important;
  min-width: 20rem;
}

.view-plan .head-details h3 {
  min-width: 15rem;
}

.view-plan .modal-content {
  overflow-y: auto;
  height: 88vh;
  padding: 24px;
}

.view-plan .ant-modal-body {
  padding: 0;
}

.down-caret path {
  fill: #fff;
}
.business_image_section {
  width: 100%;
  max-width: 100%;
}
.business_image_section .upload-sec .image_section {
  align-items: center;
  /* background: #e3e3e3; */
  border-radius: 4px;
  display: flex;
  height: 8rem !important;
  justify-content: center;
  max-width: 13rem !important;
}

.business_image_section .upload-sec .file_name {
  color: #212121;
  font-size: 1rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 10rem;
}

.business_image_section .upload-sec .file_verify {
  color: #2fcc46;
  background: #e5ffe9;
  border-radius: 4px;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
}

.business_image_section .upload-sec .preview_section,
.business_image_section .upload-sec .delete_section {
  cursor: pointer;
  width: 1.5rem;
  height: 1rem;
}

.upload-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 1.7rem;
  max-width: 100%;
  justify-content: flex-start;
  margin-top: 2rem;
}

.settings_form .upload-sec {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  width: 75%;
  max-width: 75%;
  margin: 0 auto;
  border: 2px dashed #d9d9d9 !important;
}

.settings_form .upload-show {
  flex-direction: row;
  justify-content: space-between;
}
.image_section {
  align-items: center;
  background: #fafafa;
  border-radius: 4px;
  display: flex;
  height: 8rem !important;
  justify-content: center;
  max-width: 13rem !important;
  min-width: 13rem !important;
}

.image_section img {
  max-width: 13rem !important;
}

.upload-sec .sub-title {
  color: #212121;
  font-size: 0.9rem;
  display: block;
}
.upload-sec .image_name {
  display: flex;
  align-items: flex-start;
}

.permissions-wrap input {
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  height: 35px;
  width: 5rem;
  text-align: center;
  color: #363636;
  margin-left: 4.5rem;
}

.profile-scoring .permissions-wrap .permissions ul {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.profile-scoring .ant-modal-body {
  padding: 0;
}

.profile-scoring .head-details h3 {
  min-width: 14rem !important;
  max-width: 14rem !important;
}

@media (min-width: 1330px) and (max-width: 1440px) {
  .credit-modal .ant-table-container {
    height: 46vh;
  }
}
