.actionsCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 10px;
}

.row-light .ant-table-cell-fix-right {
  background: #fff !important;
  padding: 0 !important;
}

.row-light .actionsCard {
  background: rgba(255, 116, 116, 0.2) !important;
  width: 100%;
  height: 5rem;
  padding: 0 1rem;
}

th.ant-table-cell-fix-right {
  padding: 0 !important;
  padding-left: 16px !important;
}

.editButtonDiv {
  /* background: #e4e4e4 0% 0% no-repeat padding-box; */
  opacity: 1;
  border-radius: 3px;
  padding: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333 !important;
}

.expandCardIconDiv {
  border-radius: 10px;
  opacity: 1;
  width: max-content;
  padding: 7px;
  cursor: pointer;
}

.ant-switch-checked {
  background-color: #10cb83 !important;
}

.exportButton {
  background: #e4e4e4 0% 0% no-repeat padding-box !important;
  color: #292929 !important;
  font-size: 1rem !important;
  border-radius: 5px !important;
  height: 42px;
  align-items: center;
  display: flex !important;
  justify-content: center;
  font-weight: 500;
  width: 150px !important;
  justify-content: center !important;
  cursor: pointer;
}

.ant-upload.ant-upload-drag {
  width: 350px !important;
  height: 150px !important;
  margin: 0 !important;
}

.cardTitle {
  color: #4b5660 !important;
}

.inputTag {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px !important;
  padding: 10px !important;
}

.align-items-center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-upload-btn {
  padding: 16px !important;
}

.cancelButton,
.formButton {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.cancelButton span {
  color: #1890ff !important;
}
.cancelButton {
  border-color: #1890ff !important;
  border-radius: 5px !important;
}

.formButton {
  background-color: #1890ff !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.addNewClientLeftSideCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #ed3e430a;
  border: 1px solid #edf0f5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 20px;
  line-height: 2;
  margin-right: 20px;
  height: 88vh;
  border-radius: 10px;
  min-width: 250px;
}

.leftSideButtonCircle {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #afafaf;
  border-radius: 50px;
  width: 15px;
  height: 15px;
}

.activeLeftSideButton {
  color: #333333 !important;
  font-weight: 550;
  background: #f4f4f4 0% 0% no-repeat padding-box !important;
  border-radius: 7px !important;
}

.inActiveLeftSideButton {
  color: #333333 !important;
  opacity: 0.7;
  font-weight: 550;
}

.activeLeftSideButton > button,
.inActiveLeftSideButton > button {
  color: #333333 !important;
  font-weight: 550;
}

.activeLeftSideIconTag {
  color: black;
}

.inActiveLeftSideIconTag {
  color: black;
  opacity: 0.7;
}

.labelTitle {
  color: #454545;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.expandLessCheckbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.75px solid #bfbfbf;
  border-radius: 2px;
  color: #bfbfbf;
  width: 15px;
  height: 15px;
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.childSectionMainDiavTag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2;
}

.subChildSectionMainDiavTag {
  display: flex;
  align-items: center;
  width: 300px;
}

.settings_form .ant-form-item-label label,
.radio-grp label {
  color: rgba(0, 0, 0, 0.85) !important;
}

.radio-grp {
  display: flex;
  align-items: center;
}

.radio-grp div {
  display: flex;
  flex-direction: column;
  width: 120%;
}

.radio-grp div span {
  color: rgba(0, 0, 0, 0.45) !important;
}

.radio-grp input[type="radio"] {
  width: 24px;
  height: 14px;
  margin-right: 10px;
}

.err-msg {
  color: red;
}

.dashboardSearch .ant-select-selector .ant-select-selection-search {
  left: 6px !important;
}

.preview_section .preview_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #dbf1e6 0% 0% no-repeat padding-box;
  border-radius: 7px;
  padding: 0.5rem 1rem;
}

.preview_section .info_image {
  width: 1rem;
  height: 3rem;
  margin-right: 0.5rem;
}

.preview_section .info_text {
  color: #212121;
  font-size: 1rem;
  font-weight: 600;
}

.preview_section .preview_card {
  background: #ffffff;
  border-radius: 7px;
  /* padding: 0.5rem 1rem; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.preview_section .header_section {
  padding: 1.5rem 1rem;
}

.preview_section .header_section h4 {
  color: #212121;
  font-size: 1.2rem;
  font-weight: 600;
}

.preview_section .header_line {
  border: 1px solid #cecece;
}

.preview_section .preview_data {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; */
  /* grid-auto-rows: minmax(50%, auto); */
  padding: 1.5rem 1rem;
}

.preview_section .preview_data .title {
  color: #7c7d7e;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
}

.preview_section .preview_data .value {
  color: #212121;
  font-size: 1.1rem;
  font-weight: 500;
}

.preview_section .preview_edit {
  cursor: pointer;
}

.preview_data .key_value_div {
  display: inline-block;
  width: 48%;
}

.preview_data .preview_section {
  width: 1.5rem;
  cursor: pointer;
}

.prevew_document_section .image_section {
  width: 3.5rem;
  height: 3.5rem;
}
