.loan-details {
  /* background: #ffffff; */
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  position: relative;
}

.loan-details .report-head {
  background: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.loan-details .title-sec {
  display: flex;
  align-items: center;
}

.loan-details .title-sec .status {
  border: 1px solid;
  margin-left: 1rem;
}

.sub span {
  color: #05152c !important;
  opacity: 0.5;
  font-weight: 500;
}

.sub {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.loan-details .tabs-sec {
  background: #f0f5fd;
}

.sub-tabs .tabs-sec li {
  font-size: 0.92rem;
}

.sub-tabs .tabs-sec {
  background: #f7faff;
}

.applicant-details {
  padding: 0 1.8rem;
  width: 100%;
}

.applicant-details h3,
.loan-status h3 {
  color: #122549 !important;
  font-weight: 600;
  margin-bottom: 1rem;
}

.item-detail label {
  color: #05152c !important;
  opacity: 0.5;
  font-size: 0.9rem;
  font-weight: 600;
}
.details {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.address-sec {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 1rem;
}

.address-sec .details {
  margin-bottom: 1rem;
}
.item-detail b {
  display: block;
  font-size: 1.3rem;
}
.item-detail p {
  font-weight: 700;
  font-size: 1rem;
}

.loan-sec {
  display: flex;
  gap: 2rem;
  background: #ffffff;
}

.item-detail {
  max-width: 16rem;
  min-width: 16rem;
}

.item-detail.double {
  max-width: 32rem;
  min-width: 32rem;
}

.comment-sec .ant-steps-item.ant-steps-item-finish .ant-steps-item-tail::after {
  width: 2px !important;
  height: 2rem !important;
  background-color: #3f81f4 !important;
  position: relative;
}
.comment-sec .ant-steps-item.ant-steps-item-finish .ant-steps-icon-dot {
  background: #3f81f4 !important;
}

.comment-sec .commentsSection .ant-steps-item-finish .ant-steps-item-icon,
.comment-sec .commentsSection .ant-steps-item-active .ant-steps-item-icon {
  background-color: #fff !important;
  box-shadow: 0px 3px 12px #99aecc5f;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.loan-status .ant-steps-item.ant-steps-item-finish .ant-steps-item-tail::after {
  width: 2px !important;
  height: 150% !important;
  background-color: #7cde6f !important;
  margin-top: 0 !important;
}
.loan-status .ant-steps-item.ant-steps-item-finish .ant-steps-icon-dot {
  background: #7cde6f !important;
}

.loan-status .ant-steps-item .ant-steps-item-tail::after {
  width: 2px !important;
  height: 150% !important;
  background-color: #ebc86a !important;
  margin-top: 0 !important;
  top: -0.6rem;
  position: relative;
}
.loan-status .ant-steps-item .ant-steps-icon-dot {
  background: #ebc86a !important;
}

.loan-status .ant-steps-item-title span {
  color: #11264a !important;
  font-weight: 600;
  font-size: 0.9rem;
}

.loan-status .ant-steps-item-title span.sub {
  color: rgba(17, 38, 74, 0.5);
  font-weight: 600;
  font-size: 0.7rem;
  display: inline-block;
  margin-left: 0.6rem;
}

.loan-status .ant-steps-item-description {
  color: rgba(17, 38, 74, 0.5) !important;
  font-weight: 600;
  font-size: 0.9rem;
}

.loan-details .name-details {
  display: flex;
  margin-bottom: 2rem;
}

.acc-stat button {
  background: #e2f4e9;
  border-radius: 8px;
  color: #05152c6d !important;
  padding: 0.2rem 1rem;
  font-weight: 600;
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.loan-details .line {
  width: 100%;
  margin: 2rem auto;
  height: 1px;
  background-color: #05152c1d;
}

.profile-sec .item-detail {
  min-width: 20rem;
  max-width: 20rem;
}

.profile-sec .applicant-details {
  width: 100%;
}

.director-details .contact {
  border: none;
  padding: 0;
}


.director-details .applicant-details {
  width: 100%;
}

.director-details .promoter-details .item-detail {
  max-width: 13rem;
  min-width: 13rem;
}

.documents .header .status {
  display: inline-flex;
  align-items: center;
}


.documents .header .status span {
  border: 2px solid #609842 !important;
  width: 19px;
  height: 19px;
  border-radius: 45%;
  position: relative;
  transform: scale(0.8);
  margin-right: 0.5rem;
}

.documents .header .status span img {
  position: absolute;
  top: -1px;
  left: 1.5px;
  background: #ffffff !important;
  border-radius: 60%;
}

.star {
  margin-left: 1rem;
}

.documents .header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 1rem;
}

.header .head-details > * {
  margin-bottom: 0;
}

.documents.ant-collapse {
  margin: 1rem;
  background-color: #fff !important;
  border: none;
  border-radius: 6px;
}
.documents.ant-collapse > .ant-collapse-item {
  border-radius: 6px;
}
.documents .ant-collapse-content,
.documents .ant-collapse-content-box,
.documents.ant-collapse > .ant-collapse-item {
  border: none;
}

.head-details h3 {
  color: #122549 !important;
  font-size: 1.2rem;
}
.head-details p {
  font-size: 0.8rem;
  color: #05152c80 !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.2rem;
}

.documents .ant-collapse-content {
  padding-left: 1rem;
}
.documents .ant-collapse-content-box {
  padding-top: 0;
}

.documents .actions {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.documents .view {
  background: #3f81f41c;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
}

.documents .content {
  position: relative;
  min-height: 5rem;
}

.upload-button,
.upload-btn {
  background: #ffffff !important;
  border: 1px solid #cecece;
  border-radius: 6px;
  padding: 0.5rem 2rem;
}

.documents .upload-button {
  position: absolute;
  right: 2rem;
}

.actions button {
  cursor: pointer;
}

.head-details .dim-text {
  color: #122549 !important;
  opacity: 0.5;
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.file-details span {
  color: #111 !important;
  font-weight: 500;
}

.file-details img {
  height: 5rem;
  margin-right: 1rem;
  border-radius: 4px;
}

.credit-analysis .content h3 {
  color: #122549 !important;
  font-weight: 600;
  font-size: 1.3rem;
}

.risk-score p {
  color: #122549 !important;
  font-weight: 700;
  font-size: 1.6rem;
}
.risk_status {
  background: #e2f4e9 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #05152c75 !important;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
}

.credit-analysis .ant-collapse-item-active .ant-collapse-header {
  border-bottom: 1px solid #05152c20;
}

.overdue-note {
  background: transparent linear-gradient(90deg, #11264a 0%, #4e6b9d 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  padding: 1.5rem 2rem;
}

.overdue-note p.title {
  font-weight: 600;
  font-size: 1.2rem;
  color: #fff !important;
  margin-bottom: 5px;
}

.overdue-note p.sub-title {
  font-weight: 500;
  font-size: 1rem;
  color: #ffffffba !important;
}

.overdue-note button {
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  background-color: #476394;
  color: #fff !important;
  border-radius: 5px;
  opacity: 0.7;
}

.credit-analysis .details {
  margin-bottom: 0.5rem;
  justify-content: start;
}

.details .risk_status {
  position: relative;
  top: 5px;
  display: inline-block;
}

.working-capital {
  display: flex;
  gap: 5rem;
}

.working-capital .prod-img {
  background: #d2dbeb 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 20rem;
  height: 10rem;
}

.approve-sec h3 {
  margin-bottom: 0;
}

.approve-sec .reject {
  background-color: #ae3520;
}

.income-chart .progress,
.income-chart .active-progress {
  height: 10px;
}

.income-chart span {
  color: #05152c80 !important;
}

.income-chart span.dot {
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 50%;
}

.income-chart .axis {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem;
  transform: scale(0.9);
}

.account-info {
  display: flex;
  gap: 10rem;
}

.account-info .account {
  min-width: 15rem;
  max-width: 15rem;
}

.account-info .account h2 {
  font-size: 1rem;
  margin-bottom: 5px;
}

.account p {
  color: #05152c !important;
  opacity: 0.6;
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.account .item {
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.account .item label,
.account .item span {
  color: #05152c !important;
  opacity: 0.6;
}
.account .item span {
  margin-left: 0.3rem;
}

.account .stat {
  display: inline-block;
  padding: 0.4rem 1rem;
  background: #f5f6fa 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-top: 1rem;
}

.paid,
.out {
  position: relative;
  margin-left: 1.5rem;
}
.paid::before,
.out::before {
  content: "";
  position: absolute;
  left: -1.5rem;
  top: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.paid::before {
  background-color: #1c83fc;
}

.out::before {
  background-color: #e3e9f3;
}
.emi {
  color: #122549 !important;
  font-weight: 600;
}

.credit-analysis .progress {
  margin-top: 0.5rem;
}

.credit-history p.ext {
  color: #4b5660 !important;
  font-weight: 500;
}

.credit-history {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.active-list p {
  margin-bottom: 1.5rem;
}

.active-list p span:first-child {
  color: #05152c !important;
  opacity: 0.6;
  font-size: 0.9rem;
  margin-right: 10px;
  text-align: left;
  min-width: 10rem;
  display: inline-block;
}

.active-list p span:last-child {
  color: #05152c !important;
  margin: 0 0.7rem;
  font-weight: 500;
}
.active-list p span img {
  margin-left: 5px;
}

.closed {
  background: #f1dfdc 0% 0% no-repeat padding-box;
  color: #ad3520 !important;
}

.score-check {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.score-check .progress {
  min-width: 10rem;
  max-width: 10rem;
  height: 10px;
}

.score-check .progress:first-child {
  background-color: #f9eeed;
}

.score-check .progress:nth-child(2) {
  background-color: #edf3fd;
}

.score-check .progress:nth-child(3) {
  background-color: #fdf9f5;
}

.score-check .progress:nth-child(4) {
  background-color: #59b877;
}

.dti-score {
  display: flex;
  gap: 3rem;
}

.dti-ratio {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.questionaire-wrap p {
  color: #1f1f1f !important;
  font-weight: 500;
  font-size: 1.1rem;
}
.questionaire-wrap textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  opacity: 1;
  resize: none;
  padding: 5px 10px;
  width: 80%;
}

.questionaire-wrap {
  margin-bottom: 1rem;
}
.head-details ~ .rotate {
  transform: rotate(180deg);
}

.smooth {
  transition: all 0.2s ease;
}
.geo-wrap {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.geo-img img {
  width: 10rem;
  border-radius: 8px;
  height: 8rem;
}
.geo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 8rem;
  opacity: 0.7;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.geo-location-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14rem;
  height: 8rem;
  color: #1c83fc !important;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 0 2rem;
}

.geo-loc-wrap {
  display: flex;
  gap: 2rem;
}

.content .address img {
  height: 1.8rem;
  width: 2.5rem;
  margin-bottom: 5px;
}

.addr-wrap p {
  color: #1f1f1f80 !important;
  font-weight: 500;
  margin-bottom: 5px;
}

.content .address span {
  display: block;
  color: #111 !important;
  margin-bottom: 5px;
}
.content .address {
  flex-direction: column;
}
.radio-check {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}
.radio-check label.title {
  font-weight: 500;
  min-width: 15rem;
  max-width: 15rem;
}

.radio-check label,
.radio-check span {
  color: #111 !important;
}

.content.rcu h3 {
  margin-bottom: 1.5rem;
}

.director-details .loan-status {
  width: 33% !important;
}

.gap2 {
  gap: 2rem;
}

.loan-details .ant-collapse-header {
  padding: 1.2rem !important;
}